import { useAuth } from './useAuth';
import { useErrors } from './useErrors';
import { useState, useEffect, useCallback, useRef } from 'react';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const useApi = () => {
    const { accessToken, acquireToken, isTokenExpired  } = useAuth();
    const accessTokenRef = useRef(accessToken);

    useEffect(() => {
        accessTokenRef.current = accessToken;
    }, [accessToken]);

    const apiCall = useCallback(async (method, endpoint, body = null) => {
        if (isTokenExpired()) {
            console.log("Token expired. Refreshing token.")
            await acquireToken();
            await delay(3000);
        }

        var retries = 4;
        while (accessTokenRef.current === null && retries > 0) {
            if (retries === 2) {
                await acquireToken();
            }
            //await delay(2000);
            retries--;
        }

        if (accessTokenRef.current === null) {
            console.error('AccessToken not provided for the API call.');
            return { success: false, error: 'AccessToken not provided.' };
        }

        const headers = new Headers();
        const bearer = `Bearer ${accessTokenRef.current}`;
        headers.append('Authorization', bearer);

        let isFormData = body instanceof FormData;

        if (!isFormData) {
            headers.append('Content-Type', 'application/json');
        }

        const options = {
            method: method,
            headers: headers,
            mode: 'cors',
            credentials: 'include',
        };

        if (body) {
            options.body = isFormData ? body : JSON.stringify(body);
        }

        try {
            const response = await fetch(endpoint, options);
            const data = await response.json();
            if (response.status === 200 || response.status === 202 || response.status === 304) {
                return { success: true, data: data, statusCode:  response.status};
            } 
            else if (response.status === 401 || response.status === 403) {
                console.log('AccessToken expired.');
                isTokenExpired()
                return { success: false, data: data, statusCode:  response.status };
            }
            else {
                return { success: false, data: data, statusCode:  response.status };
            }
        } catch (error) {
            console.error('Error in API call:', error);
            return { success: false, error: error };
        }
    }, [accessToken]);


    const addQueryParameters = (fileUrl, queryParamsDict) => {
        const queryParams = [];
        
        for (const key in queryParamsDict) {
            if (queryParamsDict[key] !== "" && queryParamsDict[key] !== null && queryParamsDict[key] !== undefined) {
                queryParams.push(`${key}=${encodeURIComponent(queryParamsDict[key])}`);
            }
        }
        const queryString = queryParams.join('&');
        if (queryString) {
            fileUrl.search = `?${queryString}`;
        }

        return fileUrl;
    }


    const handleDownload = async (fileUrl, fileDownloadName) => {
        try {
            const options = {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: { 
                    'Content-Type': 'application/json', 
                    'Authorization': 'Bearer ' + accessToken
                },
                mode: 'cors'
            };

            const response = await fetch(fileUrl, options);
            
            if (response.status !== 200 && response.status !== 202) {
                return { success: false };
            }

            // Recupera el contenido binario del archivo
            const blob = await response.blob();

            // Crea una URL para el archivo
            const url = window.URL.createObjectURL(blob);

            // Crea una etiqueta de enlace temporal y activa la descarga
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileDownloadName); 
            document.body.appendChild(link);
            link.click();

            // Limpieza removiendo el enlace temporal
            window.URL.revokeObjectURL(url);
            link.parentNode.removeChild(link);
            return { success: true };
        } catch (error) {
            console.error('Error en la descarga:', error);
            return { success: false };
        }
    };


  return {apiCall, addQueryParameters, handleDownload}
};