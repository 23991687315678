import { React, useState, useEffect } from 'react';
import { Box, Typography, Tabs, Dialog, DialogActions, DialogContent, 
    DialogTitle, Button, IconButton, Menu, MenuItem, Collapse } from '@mui/material';
import { HelpOutlineOutlined, Cancel, Settings as SettingsIcon, FileDownloadOutlined, Menu as MenuIcon } from '@mui/icons-material';
import {AntTab, AntDisabledTab, AntDisabledTabs, StyledDatePicker} from '../../../components/CustomStyles';
import CustomSelect from '../../../components/CustomSelect';
import { BLUE, BLACK, CONTENT_BOX, TITLE_TEXT, SELECT_TEXT } from '../../../theme';
import { useResponsive } from '../../../hooks/useResponsive';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { reportsCreditRiskService } from '../../../services/reportsCreditRiskService';
import AggregatedTable from './AggregatedTable';
import { reportsOperationalRiskService } from '../../../services/reportsOperationalRiskService';



const menuOptions = [
    'Agregado',
    'Detalles',
]

const normativeVersionsList = [
    'Reporting CRR3'
];

const executionsList = [
    'd814c0f6-d0b0-4ff2-81ab-f6706ea4f63a'
];


const SettingsMenu = ({ executionDate, handleOnChangeExecutionDate, execution, handleOnChangeExecution, normativeVersion, handleOnChangeNormativeVersion, isBelowXlScreen, isBelowMdScreen }) => {
    const { getExcelFile } = reportsOperationalRiskService();
    return (
        <Box 
            display={'flex'}
            flexDirection={isBelowMdScreen ? 'column' : 'row'}
            justifyContent={'space-evenly'}
        >
            <Button
                variant='contained'
                endIcon={<FileDownloadOutlined />}
                sx={{ mr: isBelowXlScreen ? null : '24px', fontSize: '13px', textTransform: 'none' }}
                onClick={() => getExcelFile({ year: 2024, month: "10", process_id: "d814c0f6-d0b0-4ff2-81ab-f6706ea4f63a" })}
                download={true}
            >
                Exportar Excel
            </Button>
            <Box
                display={'flex'}
                sx={{ pr: isBelowXlScreen ? null : '26px' }}
            >
                <Typography sx={{ ...SELECT_TEXT, mt: '10px', mr: '12px' }}>
                    Fecha de ejecución
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledDatePicker
                        sx={{ width: '140px' }}
                        views={['year', 'month']} 
                        value={executionDate} 
                        onChange={(value) => handleOnChangeExecutionDate(value)}
                        closeOnSelect={true}
                    />
                </LocalizationProvider>
            </Box>
            <Box
                display={'flex'}
            >
                <Typography sx={{ ...SELECT_TEXT, mt: '10px', mr: '12px' }}>
                    Versión normativa
                </Typography>
                <CustomSelect
                    selectItems={normativeVersionsList}
                    value={normativeVersion}
                    onChange={handleOnChangeNormativeVersion}
                    horizontal
                />
            </Box>
            <Box
                sx={{ pl: isBelowXlScreen ? null : '26px' }}
            >
                <CustomSelect
                    maxWidth={'150px'}
                    title={'Ejecución'}
                    selectItems={executionsList}
                    value={execution}
                    onChange={handleOnChangeExecution}
                    horizontal
                />
            </Box>
        </Box>
    );
}



export default function OperationalRiskReport() {
    const { isBelowMdScreen, isBelowXlScreen } = useResponsive();
    const [executionDate, setExecutionDate] = useState(dayjs('2024-10-01'));
    const [normativeVersion, setNormativeVersion] = useState('Reporting CRR3');
    const [execution, setExecution] = useState('d814c0f6-d0b0-4ff2-81ab-f6706ea4f63a');
    const [tab, setTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [expandedSettings, setExpandedSettings] = useState(false);
    

    useEffect(() => {
        // Check if the screen is below xl and expandedSettings is true
        if (!isBelowXlScreen && expandedSettings) {
            // Set expandedSettings to false
            setExpandedSettings(false);
        }
    }, [isBelowXlScreen]);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    const handleOnChangeExecutionDate = (event) => {
        setExecutionDate(event.target.value);
    };

    const handleOnChangeNormativeVersion = (event) => {
        setNormativeVersion(event.target.value);
    };

    const handleOnChangeExecution = (event) => {
        setExecution(event.target.value);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (index) => {
        setTab(index);
        handleMenuClose();
    };

    const handleExpandSettings = () => {
        setExpandedSettings(!expandedSettings);
    };


    return (
        <>
            <Box
                sx={CONTENT_BOX}
            >
                <Box display={'flex'} justifyContent={'space-between'} >
                    <Box display={'flex'} >
                        <Typography
                            sx={{...TITLE_TEXT, mt: '9px'}}
                            color={BLUE}
                        >
                            Riesgo Operacional
                        </Typography>
                        <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleClickOpenDialog}>
                            <HelpOutlineOutlined />
                        </IconButton>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            maxWidth='lg'
                            fullWidth
                        >
                            <DialogTitle
                                color={BLUE}
                                sx={TITLE_TEXT}
                            >
                                Riesgo Operacional
                            </DialogTitle>
                            <DialogContent >
                                {"Fichero donde se incluye el detalle del cálculo de requisitos de capital por riesgo operacional:"}{' '}<br />
                                <span>&nbsp;</span><b><span>- </span></b>{"Se simplifica el marco para el riesgo operacional: Todos los métodos de cálculo Se sustituyen por un único método estándar sensible al riesgo aplicable a todas las entidades."}<br />
                                <span>&nbsp;</span><b><span>- </span></b>{"El requisito de fondos propios por riesgo operativo será el COMPONENTE DEL INDICADOR DE ACTIVIDAD (BIC)."}<br />
                                <span>&nbsp;</span><b><span>&bull;    </span><u>{"Hoja Agregado:"}</u><br /></b>
                                <b><span>&nbsp;&nbsp;&nbsp;</span> {"i) BIC: "}</b> {"componente del indicador de actividad:"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {"-> "} </b> {"0.12 * BI, si BI ≤ 1"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {"-> "} </b> {"0.12 + 0.15 * (BI - 1), si 1 < BI ≤30"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {"-> "} </b> {"4.47 + 0.18 * (BI - 30), si BI > 30"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;</span> {"ii) BI: "}</b> {"indicador de actividad:"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {"-> "} </b> {"BI = ILDC + SC + FC"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;</span> {"iii) ILDC: "}</b> {"componente de intereses, arrendamientos y dividendos:"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {"-> "} </b> {"ILDC = min(IC, 0,0225 * AC) + DC"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;</span> {"iv) SC: "}</b> {"componente de servicios:"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {"-> "} </b> {"SC = max(OI, OE) + max(FI, FE)"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;</span> {"v) FC: "}</b> {"componente financiero:"}<br />
                                <b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {"-> "} </b> {"FC = TC + BC"}<br />
                                <span>&nbsp;</span><b><span>&bull;    </span><u>{"Hoja Detalle:"}</u><br /></b>
                            </DialogContent>
                            <DialogActions
                                sx={{ mb: '20px', justifyContent: 'center' }}
                            >
                                <Button
                                    variant='contained'
                                    sx={{ fontSize: '13px', textTransform: 'none' }}
                                    endIcon={<Cancel />}
                                    onClick={handleCloseDialog}
                                >
                                    Cerrar
                                </Button>
                            </DialogActions>
                        </Dialog >
                    </Box>
                    {!isBelowXlScreen ? (
                        <SettingsMenu 
                            executionDate={executionDate} 
                            handleOnChangeExecutionDate={handleOnChangeExecutionDate}
                            execution={execution}
                            handleOnChangeExecution={handleOnChangeExecution}
                            normativeVersion={normativeVersion}
                            handleOnChangeNormativeVersion={handleOnChangeNormativeVersion}
                        />
                    )
                    :
                    (
                        <IconButton sx={{color: BLUE}} onClick={handleExpandSettings}>
                            <SettingsIcon />
                        </IconButton>
                    )}
                </Box>
                {isBelowXlScreen && (
                    <Collapse in={expandedSettings} timeout="auto" unmountOnExit>
                        <SettingsMenu 
                            executionDate={executionDate} 
                            handleOnChangeExecutionDate={handleOnChangeExecutionDate}
                            execution={execution}
                            handleOnChangeExecution={handleOnChangeExecution}
                            isBelowXlScreen={isBelowXlScreen}
                            isBelowMdScreen={isBelowMdScreen}
                            normativeVersion={normativeVersion}
                            handleOnChangeNormativeVersion={handleOnChangeNormativeVersion}
                        />
                    </Collapse>
                )}
            </Box>
            {!isBelowXlScreen &&
            (
                <Box display={'flex'}>
                    <Tabs 
                        sx={{ml: '66px', mb: '0px', minHeight: "38px", height: "38px"}} 
                        value={tab} 
                        onChange={handleTabChange}
                    >
                        <AntTab 
                            tab={0}
                            label="Agregado" 
                        />
                        <AntTab 
                            tab={1}
                            label="Detalle" 
                        />
                    </Tabs>
                </Box>
            )}
                <Box sx={{...CONTENT_BOX, mt: '0px'}}>
                    {isBelowXlScreen && (
                        <Box display={'flex'}>
                            <Typography
                                    sx={{...TITLE_TEXT, mt: '9px'}}
                                    color={BLUE}
                                >
                                    {menuOptions[tab]}
                            </Typography>
                            <IconButton sx={{ml: '10px', color: BLUE}} onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(0)}
                                    sx={{ color: tab === 0 ? BLUE : BLACK }}
                                >
                                    Agregado
                                </MenuItem>
                                <MenuItem 
                                    onClick={() => handleMenuItemClick(1)}
                                    sx={{ color: tab === 1 ? BLUE : BLACK }}
                                >
                                    Detalle
                                </MenuItem>
                            </Menu>
                            
                        </Box>
                    )}
                    {tab === 0 && (
                        <AggregatedTable />
                    )}
                    {/* {tab === 1 && (
                        <SummaryByEntityTable />
                    )} */}
                </Box>
        </>
    );
}